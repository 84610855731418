<template>
  <div class="call-links">
    <v-row>
      <v-col class="my-4">
        <h2 class="main-color">{{ $t("Call Links") }}</h2>
      </v-col>
    </v-row>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{ $t("Class Name") }}</th>
            <th class="text-left">{{ $t("Link") }}</th>
            <th class="text-left">{{ $t("Verification Code") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in classesLinks" :key="item.name">
            <td>{{ item.class_name }}</td>
            <td>
              <span>{{ sliceLink(item.link) }}</span>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-2"
                    @click="copyText(item.link, 'link')"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("Copy Link") }}</span>
              </v-tooltip>
            </td>
            <td>
              <span>
                {{ item.verification_code }}
              </span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-2"
                    @click="copyText(item.verification_code)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("Copy Link") }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "callLinks",
  data() {
    return {
      classesLinks: [],
    };
  },
  methods: {
    getClassesLinks() {
      axios
        .get(this.getApiUrl + "/nedaa/getClassesLinks", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.classesLinks = response.data.data;
        });
    },
    sliceLink(link) {
      let str = window.location.origin + link;
      return str.slice(0, 15) + "...";
    },
    copyText(str, type = "") {
      if (type == "link") {
        let link = window.location.origin + "/" + str;
        navigator.clipboard.writeText(link);
      } else {
        navigator.clipboard.writeText(str);
      }
    },
  },
  mounted() {
    this.getClassesLinks();
  },
};
</script>

<style></style>
